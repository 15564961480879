import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { StepsConstants } from '../../Constants/StepsConstants';
import { compressPhoto, getBase64 } from '../../Helpers/LoadPhoto';
import {
    cleanSteps,
    getByPendingRequest,
    uploadBiometricsPhoto,
    validateBiometricsWithEntreConsultas
} from '../../Services/BiometricDataService';
import {
    getItemService,
    removeItemService,
    setItemService
} from '../../Services/LocalStorageService';
import { verifyBiometryRequirements } from '../../Services/OfferService';
import { LoanRequestConstantsLS } from '../LoanRequest/LoanRequestActions';
import { BNPLConstants, ORDER_STATUS } from '../../Constants/BNPLConstants';
import { BNPLOrderContext } from '../BNPLOrder/BNPLOrderContext';
import { updateOrderStatus } from '../../Services/BNPLService';
import { logEventBNPLFunnel, logEventBNPLInfo } from '../../Services/BNPLService';

const BNPLBiometricContext = createContext(null);

const BNPLBiometricProvider = ({ children }) => {
    const navigate = useNavigate();
    const { isCrossDevice, orderData } = useContext(BNPLOrderContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [isNotDescription, setIsNotDescription] = useState(false);
    const [step, setStep] = useState(0);
    const [gestureType, setGestureType] = useState(
        getItemService('gestureType') ? Number(getItemService('gestureType')) : 0
    );
    const [attempt, setAttempt] = useState(
        getItemService('attempt') ? Number(getItemService('attempt')) : 0
    );
    const inputRef = useRef();
    const [customError, setCustomError] = useState();

    const [images, setImages] = useState([
        {
            photo: getItemService(StepsConstants.FIRST_STEP)
                ? JSON.parse(getItemService(StepsConstants.FIRST_STEP)).photo
                : null,
            confirmImage: getItemService(StepsConstants.FIRST_STEP)
                ? JSON.parse(getItemService(StepsConstants.FIRST_STEP)).confirmImage
                : false
        },
        {
            photo: getItemService(StepsConstants.SECOND_STEP)
                ? JSON.parse(getItemService(StepsConstants.SECOND_STEP)).photo
                : null,
            confirmImage: getItemService(StepsConstants.SECOND_STEP)
                ? JSON.parse(getItemService(StepsConstants.SECOND_STEP)).confirmImage
                : false
        },
        {
            photo: getItemService(StepsConstants.THIRD_STEP)
                ? JSON.parse(getItemService(StepsConstants.THIRD_STEP)).photo
                : null,
            confirmImage: getItemService(StepsConstants.THIRD_STEP)
                ? JSON.parse(getItemService(StepsConstants.THIRD_STEP)).confirmImage
                : false
        },
        {
            photo: getItemService(StepsConstants.FOURTH_STEP)
                ? JSON.parse(getItemService(StepsConstants.FOURTH_STEP)).photo
                : null,
            confirmImage: getItemService(StepsConstants.FOURTH_STEP)
                ? JSON.parse(getItemService(StepsConstants.FOURTH_STEP)).confirmImage
                : false
        }
    ]);

    const [info, setInfo] = useState([]);

    const getGesture = async (pendingRequestId) => {
        try {
            if (gestureType === 0) {
                const response = await getByPendingRequest(pendingRequestId);
                if (response?.biometric?.gestureTypeId) {
                    setGestureType(response.biometric.gestureTypeId);
                    setItemService('gestureType', response.biometric.gestureTypeId);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [pendingRequestId, setPendingRequestId] = useState();

    useEffect(() => {
        const loanData = JSON.parse(localStorage.getItem(LoanRequestConstantsLS.LoanData));
        if (loanData?.pendingRequestId) {
            setPendingRequestId(loanData.pendingRequestId);
        } else {
            navigate(RoutesConstants.PUBLIC_HOME);
        }
    }, []);

    useEffect(() => {
        checkActiveStep();
    }, [images]);

    const checkActiveStep = () => {
        const firstStep =
            images[StepsConstants.FIRST_STEP] ||
            JSON.parse(getItemService(StepsConstants.FIRST_STEP));
        const secondStep =
            images[StepsConstants.SECOND_STEP] ||
            JSON.parse(getItemService(StepsConstants.SECOND_STEP));
        const thirdStep =
            images[StepsConstants.THIRD_STEP] ||
            JSON.parse(getItemService(StepsConstants.THIRD_STEP));
        const fourthStep =
            images[StepsConstants.FOURTH_STEP] ||
            JSON.parse(getItemService(StepsConstants.FOURTH_STEP));

        if (fourthStep?.photo) {
            setStep(StepsConstants.FOURTH_STEP);
            setActiveStep(StepsConstants.FOURTH_STEP);
            return;
        }

        if (thirdStep?.confirmImage) {
            setStep(StepsConstants.FOURTH_STEP);
            setActiveStep(StepsConstants.THIRD_STEP);
            return;
        }

        if (secondStep?.confirmImage) {
            setStep(StepsConstants.THIRD_STEP);
            setActiveStep(StepsConstants.THIRD_STEP);
            return;
        }
        if (secondStep?.photo) {
            setStep(StepsConstants.SECOND_STEP);
            setActiveStep(StepsConstants.SECOND_STEP);
            return;
        }

        if (firstStep?.confirmImage) {
            setStep(StepsConstants.SECOND_STEP);
            setActiveStep(StepsConstants.FIRST_STEP);
            return;
        }
    };

    const getNextPage = () => {
        const routes = {
            BNPL: RoutesConstants.FINANCING_OFFER_PAGE,
            CS: RoutesConstants.LOAN_PAY_METHOD_BNPL
        };
        return routes[localStorage.getItem(BNPLConstants.WORKFLOW)] || RoutesConstants.PUBLIC_HOME;
    };

    useEffect(() => {
        async function fetchData() {
            try {
                if (pendingRequestId) {
                    const biometryStatus = await verifyBiometryRequirements(pendingRequestId);
                    if (biometryStatus.hasBiometricData) {
                        navigate(getNextPage());
                    } else {
                        getGesture(pendingRequestId);
                    }
                }
            } catch (error) {
                console.log('error: ', error);
            }
        }
        fetchData();
    }, [pendingRequestId]);

    const handleRejectRequest = () => {
        cleanSteps();
        const rejectNextPage = () => {
            if (isCrossDevice) {
                navigate(RoutesConstants.VALIDATION_ERROR);
            } else {
                setIsRejected(true);
            }
        };
        updateOrderStatus(orderData.id, ORDER_STATUS.CANCEL)
            .then(() => {
                rejectNextPage();
            })
            .catch(() => {
                rejectNextPage();
            });
    };

    const nextAttempt = () => {
        if (attempt < process.env.REACT_APP_BIOMETRIC_MAX_ATTEMPTS) {
            setAttempt(attempt + 1);
            setItemService('attempt', attempt + 1);
        } else {
            handleRejectRequest();
        }
    };

    const nextAttemptNotBlock = () => {
        if (attempt < process.env.REACT_APP_BIOMETRIC_MAX_ATTEMPTS) {
            setAttempt(attempt + 1);
            setItemService('attempt', attempt + 1);
            return false;
        } else {
            return true;
        }
    };

    const resetAttempt = () => {
        setAttempt(0);
        setItemService('attempt', 0);
    };

    const setImage = (name, image) => {
        let data = { photo: image, confirmImage: false };
        const copyImage = [...images];
        copyImage[name] = data;
        setImages(copyImage);
    };

    const getImage = (name) => {
        return images[name].photo;
    };

    const saveImage = () => {
        let fileInput = document.getElementById('camera').files[0];
        getBase64(fileInput)
            .then((imageCompressed) => {
                setImage(step, imageCompressed);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const openCamera = (step) => {
        setStep(step);
        let fileInput = document.getElementById('camera').files[0];
        if (fileInput) {
            fileInput.value = '';
        }
        inputRef.current.children[0].click();
    };

    const saveImage2 = (image, isSelfie) => {
        if (isSelfie) {
            setImage(step, image);
        } else {
            compressPhoto(image, 1550).then((imageCompressed) => {
                setImage(step, imageCompressed);
            });
        }
    };

    const delay = (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    };

    const openCamera2 = (step) => {
        setStep(step);
        navigate(RoutesConstants.CAMERA_PAGE);
    };

    const confirmPhoto = async (body, route) => {
        setIsLoading(true);
        const localStorageHash = localStorage.getItem("orderHash");
        if (activeStep == StepsConstants.FIRST_STEP) {
            // TODO FUNNEL BNPL: First step
            // INIT
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId");
                logEventBNPLFunnel("BIOMETRIA_INTENTO", storageOrderID, localStorageHash);
            }
            // FINISH    
        }
        let data = { ...body, pendingRequestId };
        try {
            let response = await validateBiometricsWithEntreConsultas(data);
            if (response.data.response.status.toLowerCase() === 'false') {
                if (activeStep == StepsConstants.SECOND_STEP) {
                    if (response.data.response.validDniNumber.toLowerCase() === 'false') {
                        throw new Error();
                    }    
                } else {
                    throw new Error();
                }    
            }
            setIsLoading(false);
            switch (activeStep) {
                case StepsConstants.FIRST_STEP:
                    return handleFirstStep(data, route, response, StepsConstants.FIRST_STEP);
                case StepsConstants.SECOND_STEP:
                    return handleSecondStep(data, route, response, StepsConstants.SECOND_STEP);
                case StepsConstants.THIRD_STEP:
                    return handleSelfieStep(data, route, response, StepsConstants.THIRD_STEP);
                case StepsConstants.FOURTH_STEP:
                    return handleSelfieStep(data, route, response, StepsConstants.FOURTH_STEP);
                default:
                    return response;
            }
        } catch (error) {
            setIsLoading(false);
            // TODO FUNNEL BNPL: First step
            // INIT
            if ( localStorageHash )
            {
                const storageOrderIDError = localStorage.getItem("bnplOrderId");
                logEventBNPLInfo("ERROR_BIOMETRIA", storageOrderIDError, error.substring(0,250));
            }
            // FINISH    
            nextAttempt();
            return {
                ErrorType: 'customError',
                message: 'No pudimos validar tu identidad. Te pedimos que vuelvas a tomar la foto.'
            };
        }
    };

    const handleFirstStep = (data, route, response, index) => {
        if (response.data.response.status.toLowerCase() === 'false') {
            nextAttempt();
            return {
                ErrorType: 'customError',
                message: 'No pudimos validar tu identidad. Te pedimos que vuelvas a tomar la foto.'
            };
        }
        if (response.data.response.frontDNI.imageQuality.toLowerCase() === 'mala' 
	    && response.data.response.status === 'false') {
            nextAttempt();
            return {
                ErrorType: 'customError',
                message: 'No pudimos validar tu identidad. Te pedimos que vuelvas a tomar la foto.'
            };
        }
        if (response.data.response.description.toLowerCase() === 'false') {
            const pass = nextAttemptNotBlock();
            if (pass == false) {
                return {
                    ErrorType: 'customError',
                    message: 'No pudimos validar tu identidad. Te pedimos que vuelvas a tomar la foto.'
                };    
            } else {
                setIsNotDescription(true);
            }
        }
        if (response.data.response.validDniNumber.toLowerCase() === 'false') {
            handleRejectRequest();
        } else {
            // TODO FUNNEL BNPL: First step
            // INIT
            const localStorageHash = localStorage.getItem("orderHash");
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("CONFIRMA_BIOMETRIA_FRENTE", storageOrderID, localStorageHash)
            }
            // FINISH
            confirmImage({ ...data, status: 'true', index: index });
        }
        //route ? navigate(route) : '';
        return response;
    };

    const handleSecondStep = (data, route, response, index) => {
        if (response.data.response.status.toLowerCase() === 'false') {
            if (response.data.response.validDniNumber.toLowerCase() === 'false') {
                handleRejectRequest();
            } else if (response.data.response.validDniNumber.toLowerCase() === 'null') {
                if (isNotDescription == false) { 
                    nextAttempt();
                    return {
                        ErrorType: 'customError',
                        message: 'No pudimos validar tu identidad. Te pedimos que vuelvas a tomar la foto.'
                    };
                }
            }
        }
        if (response.data.response.validDniNumber.toLowerCase() === 'false') {
            handleRejectRequest();
        } else if (response.data.response.validDniNumber.toLowerCase() === 'null') {
            if (isNotDescription == false) { 
                nextAttempt();
                return {
                    ErrorType: 'customError',
                    message: 'No pudimos validar tu identidad. Te pedimos que vuelvas a tomar la foto.'
                };
            }
        }

        setIsNotDescription(false);

        // TODO FUNNEL BNPL: First step
        // INIT
        const localStorageHash = localStorage.getItem("orderHash");
        if ( localStorageHash )
        {
            const storageOrderID = localStorage.getItem("bnplOrderId")
            logEventBNPLFunnel("CONFIRMA_BIOMETRIA_DORSO", storageOrderID, localStorageHash)
        }
        // FINISH
        confirmImage({ ...data, status: 'true', index: index });
        //route ? navigate(route) : '';
        return response;
    };

    const handleSelfieStep = async (data, route, response, index) => {
        let confirm = false;

        if (response.data.response.status.toLowerCase() === 'true') {
            confirm = true;
        }
        response.data.response.selfies.forEach((selfie) => {
            if (selfie.match.toLowerCase() === 'true') {
                confirm = true;
            } else if (selfie.match.toLowerCase() === 'false') {
                confirm = false;
            } else {
                confirm = false;
                nextAttempt();
                return {
                    ErrorType: 'customError',
                    message:
                        'No pudimos validar tu identidad. Te pedimos que vuelvas a tomar la foto.'
                };
            }
        });
        if (confirm) {
            // TODO FUNNEL BNPL: First step
            // INIT
            const localStorageHash = localStorage.getItem("orderHash");
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("CONFIRMA_BIOMETRIA_SELFIE_" + index, storageOrderID, localStorageHash)
            }
            // FINISH
            confirmImage({ ...data, status: 'true', index: index });
        }
        return response;
    };

    const confirmImage = (data) => {
        let info = {
            photo: images[data.step].photo,
            confirmImage: true,
            extension: data.extension,
            biometricDataType: data.biometricDataType,
            status: data.status
        };
        const image = {
            photo: images[data.step].photo,
            confirmImage: true
        };
        const copyImage = [...images];
        copyImage[data.step] = image;
        setImages(copyImage);
        setInfo((prevInfo) => {
            let newInfo = [...prevInfo];
            newInfo[data.index] = info;
            return newInfo;
        });
        resetAttempt();
    };

    useEffect(() => {
        if (info[StepsConstants.FIRST_STEP] && 
            info[StepsConstants.SECOND_STEP] && 
            info[StepsConstants.THIRD_STEP] && 
            info[StepsConstants.FOURTH_STEP]
            ) {
            sendPhotosToLoan();
        }
    }, [info]);

    const sendPhotosToLoan = async () => {
        const step1 = info[StepsConstants.FIRST_STEP];
        const step2 = info[StepsConstants.SECOND_STEP];
        const step3 = info[StepsConstants.THIRD_STEP];
        const step4 = info[StepsConstants.FOURTH_STEP];
        
        try {
            console.log(Date.now());
            let response = await uploadBiometricsPhoto({
                        frontDNI: getImage(StepsConstants.FIRST_STEP),
                        pendingRequestId: pendingRequestId,
                        ...step4
                    });
            delay(1000);
            console.log(Date.now());
            let response2 = await uploadBiometricsPhoto({
                        frontDNI: getImage(StepsConstants.FIRST_STEP),
                        pendingRequestId: pendingRequestId,
                        ...step3
                    });
            delay(1000);
            console.log(Date.now());
            let response3 = await uploadBiometricsPhoto({
                        frontDNI: getImage(StepsConstants.FIRST_STEP),
                        pendingRequestId: pendingRequestId,
                        ...step2
                    });
            delay(1000);
            console.log(Date.now());
            let response4 = await uploadBiometricsPhoto({
                        frontDNI: getImage(StepsConstants.FIRST_STEP),
                        pendingRequestId: pendingRequestId,
                        ...step1
                    });

            cleanSteps();
            removeItemService('gestureType');
            navigate(getNextPage());
        } catch (error) {
            console.log(error);
            setCustomError(
                'No pudimos cargar tu imagen. Te pedimos que vuelvas a tomar la foto.'
            );
        }
    };

    const state = {
        activeStep,
        step,
        inputRef,
        images,
        isLoading,
        isRejected,
        gestureType,
        customError
    };

    const actions = {
        setActiveStep,
        openCamera,
        openCamera2,
        saveImage2,
        confirmPhoto,
        setImage,
        saveImage,
        getImage,
        getGesture
    };

    return (
        <BNPLBiometricContext.Provider
            value={{
                state,
                actions
            }}>
            {children}
        </BNPLBiometricContext.Provider>
    );
};

export { BNPLBiometricContext, BNPLBiometricProvider };
